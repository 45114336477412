import React from "react"

import { Container } from "react-bootstrap"
import Layout from "components/layout"
import SEO from "components/seo"
import FileUpload from "components/FileUpload/FileUpload"

const DemoPage = () => {
  return (
    <div className="background-grey">
      <Layout pageInfo={{ pageName: "demo" }}>
        <SEO title="Demo" keywords={[`gatsby`, `react`, `bootstrap`]} />
        <Container fluid className="px-0">
          <FileUpload title="Upload" />
        </Container>
      </Layout>
    </div>
  )
}

export default DemoPage
