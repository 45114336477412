import React, { useState } from "react"
import styles from "./Dropzone.module.scss"
import classNames from "classnames"
import { Button } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faUpload } from "@fortawesome/free-solid-svg-icons"
import { navigate } from "gatsby"

const Dropzone = ({
  children,
  disabled = false,
  uploadText,
  dropText,
  filesAdded,
  files,
}: DropzoneProps) => {
  const [highlighted, setHighlighted] = useState(false)

  const fileInputRef = React.createRef<any>()

  const openFileDialog = () => {
    if (disabled) {
      return
    }
    fileInputRef.current.click()
  }

  const onFilesAdded = evt => {
    if (disabled) {
      return
    }
    const files = evt.target.files
    const array = fileListToArray(files)
    filesAdded(array)
    evt.target.value = null
  }

  const fileListToArray = list => {
    const array = []
    for (var i = 0; i < list.length; i++) {
      array.push(list.item(i))
    }
    return array
  }

  const onDragOver = evt => {
    evt.preventDefault()
    if (disabled) {
      return
    }
    setHighlighted(true)
  }

  const onDragLeave = () => {
    setHighlighted(false)
  }

  const onDrop = event => {
    event.preventDefault()
    if (disabled) {
      return
    }

    const files = event.dataTransfer.files
    if (onFilesAdded) {
      const array = fileListToArray(files)
      filesAdded(array)
    }
    setHighlighted(false)
  }

  const navigateToManualEntry = () => {
    navigate("/manualEntry", {
      state: {manualEntry: true},
    })
  }

  return (
      <div
        aria-hidden="true"
        className={classNames(
          styles.dropzone,
          highlighted ? styles.highlight : null
        )}
        onDragOver={onDragOver}
        onDragLeave={onDragLeave}
        onDrop={onDrop}
      >
        <div
          className={
            files.length > 0 ? styles.horizontalDisplay : styles.verticalDisplay
          }
        >
          <FontAwesomeIcon className={styles.icon} icon={faUpload} />
          <input
            ref={fileInputRef}
            className={styles.fileInput}
            type="file"
            accept="image/png, image/jpeg, .pdf"
            multiple
            onChange={(event) => { 
               onFilesAdded(event) 
            }}
          />
          <span className={styles.dropzoneText}>
            {highlighted ? dropText : uploadText}
          </span>
          <span>or</span>
          <Button
            className={classNames("sort-it-button", styles.dropzoneButton)}
            onClick={openFileDialog}
          >
            Browse For File
          </Button>
          {(files.length < 1 && 
            <>
              or
              <Button
                className={classNames("sort-it-button")}
                onClick={() => navigateToManualEntry()}
              >
                Continue without floorplan
              </Button>
            </>
            )}
        </div>
        {children}
      </div>
  )
}

export default Dropzone
