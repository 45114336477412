import React from "react"
import styles from "./FileUploadRow.module.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faFile,
  faFileImage,
  faFilePdf,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons"

const FileUploadRow = ({ file, rowIndex, totalRows, removeFile }: FileUploadRowProps) => {
  const getFileIcon = (file: File) => {
    switch (file.type) {
      case "image/jpeg":
      case "image/png":
        return faFileImage
      case "application/pdf":
        return faFilePdf
      default:
        return faFile
    }
  }

  return (
    <div className={styles.fileRow}>
      <FontAwesomeIcon className={styles.fileIcon} icon={getFileIcon(file)} />
      <span className={styles.fileName}>{file.name}</span>
      <span className={styles.fileCount}>
        {rowIndex + 1}/{totalRows}
        <FontAwesomeIcon
          onClick={() => {
            removeFile(rowIndex)
          }}
          icon={faTrashAlt}
          className={styles.deleteIcon}
        />
      </span>
    </div>
  )
}
export default FileUploadRow
