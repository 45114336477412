import React, { useState } from "react"
import styles from "./FileUpload.module.scss"
import Dropzone from "components/Dropzone/Dropzone"
import FileUploadList from "components/FileUploadList/FileUploadList"
import { Button, Container, Row, Toast } from "react-bootstrap"
import classNames from "classnames"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCross, faSpinner } from "@fortawesome/free-solid-svg-icons"
import { navigate } from "gatsby"
import { UPLOAD_FILES_URL } from "src/data/urls"

const FileUpload = ({ title }: FileUploadProps) => {
  const [files, setFiles] = useState<File[]>([])
  const [uploading, setUploading] = useState(false)
  const [uploadError, setUploadError] = useState(false)

  const toastTimer = 3000

  var blobInfo: AzureBlobInfo[] = []

  const addFiles = (filesArray: File[]) => {
    setFiles(files.concat(filesArray))
  }

  const uploadFiles = async () => {
    setUploading(true)
    const promises: Promise<any>[] = []
    files.forEach(file => {
      promises.push(sendUploadFileRequest(file))
    })
    await Promise.all(promises).then(
      (result: string[]) => {
        result.forEach(res => {
          const fileInfo: AzureBlobInfo[] = JSON.parse(res);
          blobInfo.push(fileInfo[0]);
        })
        navigate("/results", {
          state: {
            blobInfo: blobInfo,
          },
        })
        setUploading(false)
        setFiles([])
      },
      error => {
        //todo some error handling here...
        setUploadError(true)
        setUploading(false)
        setFiles([])
      }
    )
  }

  const sendUploadFileRequest = (file: File) => {
    return new Promise((resolve, reject) => {
      var data = new FormData()
      data.append("files", file)

      var xhr = new XMLHttpRequest()
      xhr.addEventListener("readystatechange", function() {
        if (this.readyState === 4) {
          if (this.status === 200) {
            resolve(this.responseText)
          } else {
            reject(this.responseText)
          }
        }
      })
      xhr.open("POST", UPLOAD_FILES_URL)
      xhr.send(data)
    })
  }

  const removeFile = (index: number) => {
    files.splice(index, 1)
    setFiles([...files])
  }

  return (
    <>
      <div>
        <Container fluid className="px-0">
          <Row className="justify-content-center mx-0">
            <div className={styles.pageTitle}>{title}</div>
          </Row>
          <Row className="justify-content-around mx-0">
            <Dropzone
              filesAdded={filesArray => addFiles(filesArray)}
              disabled={uploading}
              files={files}
              uploadText={"Drag and drop a file here"}
              dropText={"Drop the file here"}
            >
              <FileUploadList
                files={files}
                removeFile={(index: number) => removeFile(index)}
              />
            </Dropzone>
            <div className={styles.toastContainer}>
              <Toast
                show={uploadError}
                onClose={() => {
                  setUploadError(false)
                }}
                delay={toastTimer}
                autohide
              >
                <Toast.Header>
                  <FontAwesomeIcon
                    icon={faCross}
                    className={styles.sortItColour}
                  />
                  <strong className="mr-auto ml-3">Error</strong>
                </Toast.Header>
                <Toast.Body>
                  One or more of your files were not uploaded
                </Toast.Body>
              </Toast>
            </div>
          </Row>
          <Row className="justify-content-center mx-0">
            <Button
              disabled={files.length < 1 || uploading}
              className={classNames(
                "sort-it-button",
                files.length < 1 || uploading ? null : styles.enlargeOnHover,
                styles.buttonMargin,
                uploading ? styles.hidden : null
              )}
              onClick={uploadFiles}
            >
              SORT IT!
            </Button>
            <div className={classNames(uploading ? styles.spinner : styles.hidden)}>
              <FontAwesomeIcon icon={faSpinner} spin />
            </div>
          </Row>
        </Container>
      </div>
    </>
  )
}
export default FileUpload
