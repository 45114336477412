import React from "react"
import styles from "./FileUploadList.module.scss"
import FileUploadRow from "components/FileUploadRow/FileUploadRow"

const FileUploadList = ({ files, removeFile }: FileUploadListProps) => {
  return (
    <div className={styles.files}>
      {files.map((file, index) => {
        return (
          <FileUploadRow
            key={`${file.name}_${index}`}
            file={file}
            rowIndex={index}
            totalRows={files.length}
            removeFile={(index: number) => removeFile(index)}
          />
        )
      })}
    </div>
  )
}
export default FileUploadList