const BASE_URL = "https://api.sortit.ai/";
const BASE_URL_DEV = "https://devapi.sortit.ai/";

export const DRAWING_LAYOUT_URL = buildUrl("api/documents/determineDrawingLayout"); 
export const UPLOAD_FILES_URL = buildUrl("api/documents/uploadFiles");
export const DOCUMENT_TYPE_URL = buildUrl("api/documents/determineDocumentType");
export const ELEMENT_DETECTION_URL = buildUrl("api/elementRecogniser/getElementsFromImage");

function buildUrl (url: string) {
    const isBrowser = typeof window !== "undefined";
    let baseUrl = BASE_URL_DEV;

    if (isBrowser) {
        const isDev = window.location.hostname.includes("devapp.sortit.ai") || window.location.hostname.includes("localhost");
        baseUrl = isDev ? baseUrl : BASE_URL;
    }
    return baseUrl + url
}